<template>
  <div class="appImage relative w-full h-auto" :class="[`ratio-${ratio}`]">
    <div
      v-if="isLoading"
      class="absolute top-0 start-0 w-full h-full bg-sky-200"
    ></div>
    <img
      v-else
      :src="src"
      :alt="alt"
      width=""
      height=""
      :loading="loading"
      class="absolute top-0 start-0 w-full h-full"
      :class="[fit, imageClass]"
    />
  </div>
</template>

<script lang="ts" setup>
import {useImage} from "@vueuse/core";

type Props = {
  src: string;
  alt?: string;
  loading?: "lazy" | "eager";
  fit?: "contain" | "cover" | "fill" | "none";
  ratio?: string;
  imageClass?: string;
};

const props = withDefaults(defineProps<Props>(), {
  alt: "image",
  loading: "lazy",
  fit: "contain",
  ratio: "1-1",
});

const {src, alt, fit} = toRefs(props);

const {isLoading, ...rest} = useImage({src: src.value});
</script>

<style lang="postcss" scoped>
.appImage {
  &.ratio {
    &-1-1 {
      @apply aspect-square;
    }
    &-2-3 {
      @apply aspect-[2/3];
    }
    &-3-2 {
      @apply aspect-[3/2];
    }
    &-2-3 {
      @apply aspect-[2/3];
    }
    &-2-4 {
      @apply aspect-[2/4];
    }
    &-4-2 {
      @apply aspect-[4/2];
    }
    &-4-1 {
      @apply aspect-[4/1];
    }
    &-2-4 {
      @apply aspect-[2/4];
    }
    &-4-3 {
      @apply aspect-[4/3];
    }
    &-5-2 {
      @apply aspect-[5/2];
    }
    &-5-3 {
      @apply aspect-[5/3];
    }
    &-5-4 {
      @apply aspect-[5/4];
    }
  }

  img {
    &.cover {
      @apply object-cover;
    }
    &.contain {
      @apply object-contain;
    }

    &.fill {
      @apply object-fill;
    }
  }
}
</style>
